<template>
    <div v-if="showSwiper" class="running-line">
        <Swiper :autoplay="{
        delay: 1,
        disableOnInteraction: false
    }" :centered-slides="true" :allow-touch-move="false" slides-per-view="auto" :space-between="4" :loop="true"
            :free-mode="true" :modules="modules" :speed="speed" :dir="rtl && 'rtl'">
            <SwiperSlide v-if="tags.length && tagsProjects.length">
                <span v-for="(item, i) in tags" :key="i">
                    <NuxtLink :to="tagsProjects[i]" data-cta="view project" @click.native="handleTagClick(item)">
                        {{ item }}
                    </NuxtLink>
                </span>
            </SwiperSlide>
            <SwiperSlide v-if="tags.length && tagsProjects.length">
                <span v-for="(item, i) in tags" :key="i + '-copy'">
                    <NuxtLink :to="tagsProjects[i]" data-cta="view project" @click.native="handleTagClick(item)">
                        {{ item }}
                    </NuxtLink>
                </span>
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<script lang="ts" setup>
import { Pagination, FreeMode, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/free-mode'
import 'swiper/css/autoplay'
import { onMounted, ref, computed } from '#imports'
import json from '@/assets/data/projects-extended.json'

const modules = [Pagination, FreeMode, Autoplay]

interface Project {
    id: number | string
    title: string
    slug: string
    tags?: string[]
}

interface Props {
    tags: string[]
    rtl: boolean
    speed: number
}

const props = defineProps<Props>()
const emit = defineEmits(['tag-click'])
const projects = ref<Project[]>([])

const showSwiper = ref(false)

const tagsProjects = computed(() => {
    return props.tags.map((tag) => {
        const tagToLowerCased = tag.toLowerCase()

        const matchedProject = projects.value.find(project =>
            project.tags?.some(projectTag => projectTag.toLowerCase() === tagToLowerCased)
        )

        return matchedProject ? `/work/${matchedProject.slug}` : '/work'
    })
})

const handleTagClick = (tag: string) => {
    emit('tag-click', tag)
}

async function fetchProjects() {
    try {
        projects.value = json as Project[] | any
    } catch (e) {
        console.error(e)
    }
}

await fetchProjects()

onMounted(() => (setTimeout(() => (showSwiper.value = true), 500)))
</script>