<template>
  <section class="the-how-section container section">
    <div class="section-number-heading the-how-section__section-number-heading">
      <span>01</span>
      <h2>The how</h2>
    </div>

    <div class="section-head-description">
      Our work does not just get noticed. It gets the conversation going. And it
      gets the job done.
    </div>

    <div class="the-how-section__slideshow-container">
      <ul v-if="projects?.length" class="the-how-section__list" ref="slideshowRef">
        <li v-for="(item, i) in items" :key="item.id" class="the-how-section__item"
          :class="[{ active: activeItem === i }, `the-how-section__item-${i}`]">

          <div class="the-how-section__text-content">
            <div class="the-how-section__count">
              {{ getPositionCount(i + 1) }}
            </div>

            <h3 v-if="item.title" class="the-how-section__title">
              {{ item.title }}
            </h3>
          </div>

          <div v-if="item.picture" class="the-how-section__photo">
            <nuxt-link :to="`work/${item.slug}`" class="stretched-link" :data-cta="item.title">
              <AdaptiveImage :image="item.picture" :alt="`${item.title} image`" />
            </nuxt-link>
          </div>

          <div class="the-how-section__text-content">
            <div v-if="item.date" class="the-how-section__timestamp">
              {{ item.date }}
            </div>
          </div>

          <div class="the-how-section__project-link">
            <span>View project</span>
            <img :src="'/img/icons/arrow-right.svg'" alt="Link arrow" />
          </div>
        </li>
      </ul>

      <nuxt-link v-if="canScrollRight" @click="scrollRight"
        class="the-how-section__scroll-button the-how-section__scroll-button--right" data-cta="Next">
        →
      </nuxt-link>

      <nuxt-link v-if="canScrollLeft" @click="scrollLeft"
        class="the-how-section__scroll-button the-how-section__scroll-button--left" data-cta="Previous"
        data-arrow="left">
        ←
      </nuxt-link>

      <nuxt-link v-if="isLastItemActive" to="/work" class="the-how-section__see-all-button" data-cta="See All">
        See All
      </nuxt-link>
    </div>
  </section>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref, computed } from "#imports";
import { headingStartAnimation, fromOpacityAnimationSlideshow } from "~/utils/gsap";
import AdaptiveImage from "~/components/universal/AdaptiveImage.vue";

interface Project {
  id: number | string;
  title: string;
  slug: string;
  lead: string;
  picture: {
    default: string;
    webp: string;
  };
  picture_header: {
    default: string;
    webp: string;
  };
  client: string;
  client_link: string;
  info_client: string;
  info_about: string;
  active?: boolean;
  date?: string;
  link?: string;
}

const props = defineProps<{ projects: Project[] }>();
const activeItem = ref(0);
const slideshowRef = ref<HTMLUListElement | null>(null);
const canScrollLeft = ref(false);
const canScrollRight = ref(true);

const getPositionCount = (i: number): string =>
  `${i}`.length === 1 ? `0${i}` : `${i}`;

const items = computed(() => {
  if (props.projects) {
    return props.projects.map((item) => ({ ...item, active: false }));
  }
  return [];
});

const isLastItemActive = computed(() => activeItem.value === items.value.length - 1);

const scrollRight = () => {
  const container = slideshowRef.value;
  if (!container) return;
  const itemWidth = (container.firstElementChild as HTMLElement)?.offsetWidth || 0;

  if (activeItem.value < items.value.length - 1) {
    container.scrollBy({ left: itemWidth, behavior: "auto" });
    activeItem.value = Math.min(activeItem.value + 1, items.value.length - 1);

    setTimeout(() => {
      updateScrollButtons();
    }, 100);
  }
};


const scrollLeft = () => {
  const container = slideshowRef.value;
  if (!container) return;
  const itemWidth = (container.firstElementChild as HTMLElement)?.offsetWidth || 0;
  if (activeItem.value > 0) {
    container.scrollBy({ left: -itemWidth, behavior: "auto" });
    activeItem.value = Math.max(activeItem.value - 1, 0);
    nextTick(() => {
      updateScrollButtons();
    });
  }
};

const updateScrollButtons = () => {
  const container = slideshowRef.value;
  if (!container) return;
  canScrollLeft.value = activeItem.value > 0;
  canScrollRight.value = activeItem.value < items.value.length - 1;
};

const setActiveItem = (entry: IntersectionObserverEntry) => {
  const datasetIndex = (entry.target as HTMLElement).dataset.index;
  const index = datasetIndex ? parseInt(datasetIndex, 10) : -1;

  if (entry.isIntersecting) {
    activeItem.value = index;
  } else {
    if (activeItem.value === index) {
      activeItem.value = -1;
    }
  }
};

const preventScroll = (event: Event) => {
  event.preventDefault();
};

onMounted(() => {
  activeItem.value = 0;

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        setActiveItem(entry);
      });
    },
    {
      root: slideshowRef.value,
      threshold: 0.5,
      rootMargin: '0px',
    }
  );

  const container = slideshowRef.value;
  if (container) {
    const items = container.querySelectorAll('.the-how-section__item');
    items.forEach((item) => observer.observe(item));

    container.addEventListener('wheel', preventScroll, { passive: false });
    container.addEventListener('touchmove', preventScroll, { passive: false });
  }

  updateScrollButtons();

  if (container) {
    container.addEventListener('scroll', updateScrollButtons);
  }

  nextTick(() => setTimeout(() => {
    headingStartAnimation('.the-how-section__section-number-heading')
    fromOpacityAnimationSlideshow('.the-how-section__item', items.value.length - 1,)
  }, 500))
});
</script>