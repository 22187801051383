import { ref, onMounted, onUnmounted } from "vue";

export function useZoom() {
    const zoomLevel = ref(1);

    function updateZoom() {
        if (typeof window !== "undefined") {
            const screenWidth = window.screen.width;
            const viewportWidth = window.innerWidth;

            zoomLevel.value = Math.round((screenWidth / viewportWidth) * 100) / 100;
        }
    }

    onMounted(() => {
        updateZoom();
        window.addEventListener("resize", updateZoom);
    });

    onUnmounted(() => {
        window.removeEventListener("resize", updateZoom);
    });

    return { zoomLevel };
}