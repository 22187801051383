<template>
    <section class="welcome container">
        <video
            v-if="jimmyFantasticData.video_header"
            autoplay
            loop
            muted
            playsinline
            class="single-work-head__background-video"
        >
            <source :src="jimmyFantasticData.video_header" type="video/mp4">
        </video>
        <AdaptiveImage
            v-else-if="jimmyFantasticData.picture_header"
            :image="jimmyFantasticData.picture_header"
            :alt="`${jimmyFantasticData.title} image`"
            class-name="single-work-head__background-image"
        />
        <!-- <transition name="fade">
            <div v-if="bgReady" class="welcome__background">
                <div v-for="i in 2" :key="i" />
            </div>
        </transition> -->

        <h1 class="welcome__heading" :class="{'zoomed': zoomLevel > 1.2}">
            <nuxt-link class="welcome__text__cta" to="/work">
                <span data-cta="our-portfolio">We</span>
                <span data-cta="our-portfolio">are</span>
                <span data-cta="our-portfolio">Spellcaster</span>
            </nuxt-link>
        </h1>

        <div class="welcome__main" :class="{'zoomed': zoomLevel > 1.2}">
            <nuxt-link class="welcome__text__cta" to="/work">
                <span v-if="welcomeData.description" data-cta="our-portfolio">
                    {{ welcomeData.description }}
                </span>
            </nuxt-link>
        </div>

        <nuxt-link :to="`/work`" class="the-how-section__project-link-welcome">
            <span>Our portfolio</span>
            <img :src="'/img/icons/arrow-right.svg'" alt="Link arrow">
        </nuxt-link>
    </section>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, nextTick } from '#imports';
import gsap from 'gsap';
import { useZoom } from '@/composables/useZoom';
import json from '@/assets/data/projects-extended-short.json';
import { CIRCLE_BUTTON_ARROWS } from '~/types/buttons';
import { type WelcomeSectionData } from '~/types/welcome-section';
import { mdBreakpoint } from '~/constants';
import AdaptiveImage from '../universal/AdaptiveImage.vue';

const { zoomLevel } = useZoom();

const jimmyFantasticData = json[2];

const welcomeData = ref<WelcomeSectionData>({
    heading: 'We are Spellcaster',
    description: 'Spellcaster is an independent and international pop-up creative shop. We are a collective of curious and driven problem-solvers.'});

const bgReady = ref(false);

const startContentAnimation = () => {
    if (window.innerWidth < mdBreakpoint) return;
    const trigger = '.welcome';

    gsap.timeline({
        scrollTrigger: { trigger, start: 'top 10%' }
    }).from(trigger, 1.8, { opacity: 0, y: 100, ease: 'power4.out', skewY: 7, stagger: { amount: 0.3 } });
};

onMounted(() => nextTick(() => {
    startContentAnimation();
    setTimeout(() => (bgReady.value = true), 500);
}));
</script>
